/*
**********************************************
* Registeration layout
**********************************************
*/

.RegisterLayout {
  position: relative;
  overflow: hidden;
  padding: 20px 40px 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;

  @media (max-width: 1600px) {
    max-width: 1280px;
  }

  .RegisterLayoutContainer {
    background: $body-color;
    //box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
    //border-radius: 24px;

    width: 100%;
    height: 100%;
    overflow: scroll;

    .stepsContainer {
      border-radius: 24px;

      //&.bg-blue{
      //  max-width: 365px;
      //}
      .stepsSettingLeft {
        padding-left: 135px;
        padding: 176px 63px 176px 96px;

        @media (max-width: 1366px) {
          padding: 50px;
        }

        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 49px;
          color: $theme-white;
          margin-bottom: 50px;

          @media (max-width: 1366px) {
            font-size: 24px;
          }

          @media (max-width: 767px) {
            margin: 0;
          }
        }

        .list-steps {
          position: relative;
          padding-left: 46px;

          li {
            margin-bottom: 30px;
            opacity: 0.5;

            &:last-child {
              margin-bottom: 0;
            }

            &.active {
              opacity: 1;
            }

            a {
              font-family: $baseFont;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              color: $theme-white;
              line-height: 36px;
              text-decoration: none;

              @media (max-width: 1366px) {
                font-size: inherit;
              }
            }

            position: relative;

            &::before {
              content: "";
              width: 24px;
              height: 24px;
              background: rgba(255, 255, 255, 0.5);
              position: absolute;
              left: -44px;
              top: 50%;
              transform: translate(0, -50%);
              display: block;
              border: 2px solid #fff;
              border-radius: 100%;
            }

            &:not(:last-child)::after {
              content: '';
              width: 2px;
              height: 26px;
              position: absolute;
              background: $theme-white;
              left: -33px;
              bottom: -28px;
            }
          }
        }
      }

      .stepsSettingRight {
        //background: red;
        position: relative;
        height: 100%;

        .paddingContainer {
          padding: 0 255px;
          margin-top: 32px;

          @media (max-width: 1200px) {
            padding: 0 170px;
          }

          @media (max-width: 1024px) {
            padding: 0 30px;
          }

          @media (max-width: 767px) {
            padding: 30px;
            margin: 0;
          }

          input {
            max-width: 370px;

            @media (max-width: 1366px) {
              padding: .375rem .75rem;
            }
          }
        }

        .stepCounters {
          display: block;
          padding-top: 75px;
          //background: blue;
          width: 100%;
          font-family: $baseFont;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: $theme-blue;
          text-align: center;
          text-transform: uppercase;

          @media (max-width: 767px) {
            padding-top: 30px;
            margin-top: 2rem;
          }
        }

        h3 {
          font-family: $baseFont;
          font-weight: 700;
          font-size: 22px;
          line-height: 30px;
          color: $theme-black;
          text-align: center;

          @media (max-width: 1366px) {
            font-size: inherit;
          }
        }

        /*
        * radio groups specific to this sign up process only
        */
        .radioGroup {
          label {
            max-width: 170px;
            max-height: 200px;
            background: $theme-white;
            border-bottom: 2px solid $theme-white;
            transition: all 0.5s ease;
            text-align: center;
            padding: 40px 50px;

            @media (max-width: 1366px) {
              padding: 30px 50px;
            }

            &:hover {
              cursor: pointer;
            }

            svg {
              path {
                fill: $label-grey;
              }
            }

            span {
              display: block;
              width: 100%;
              font-family: $baseFont;
              font-weight: 700;
              font-size: 22px;
              line-height: 30px;
              /* identical to box height */

              text-align: center;
              margin-top: 32px;

              @media (max-width: 1366px) {
                font-size: inherit;
                margin-top: 14px;
              }
            }
          }

          input[type='radio']:checked~label {
            border-bottom: 2px solid $theme-blue;
            box-shadow: 0px 15px 20px rgba(184, 200, 224, 0.25);

            svg {
              path {
                fill: $theme-blue;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }
        }

        /*
        * bottom bar that repeats on overall screens of registration process
        */
        .bottomBar {
          border-top: 1px solid $border-grey;
          padding: 17px 50px;
          background: $theme-white;
          position: absolute;
          bottom: 0;
          width: 100%;
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;

          @media (max-width: 767px) {
            position: relative;
            padding: 12px;
          }

          .list-inline {
            @media (max-width: 767px) {
              margin-top: 15px;
              display: flex;
              justify-content: center;
            }
          }

          p {
            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .btn-submit {
            text-align: center;
            padding: 14px 17.5px;
            width: 145px;
            border-radius: 14px;

            //max-height: 50px;
            span {
              transform: translateX(15px);
              transition: all 0.5s ease;
            }

            &:hover,
            &:focus {
              span {
                transform: translateX(0);
              }
            }
          }
        }


      }

    }

  }
}