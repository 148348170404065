.rumSelect__option--is-selected {
    color: $theme-blue !important;
}

.css-1nmdiq5-menu {
    position: relative;
    z-index: 9999999;
}

.rumSelect {
    height: 50px;
    width: 166px;

    &.outlineBtn {
        .rumSelect__control {
            .rumSelect__placeholder {
                color: $theme-blue;
            }

            .rumSelect__indicator {
                svg {
                    fill: $theme-blue;
                }
            }
        }

    }

    @media (max-width: 1366px) {
        width: 145px;
    }

    @media (max-width: 992px) {
        height: 42px;
    }

    .css-1xc3v61-indicatorContainer {
        padding: 0;
    }

    .css-blhudp-control,
    .css-v68sna-control {
        height: 100%;
        padding: 0 12px;
        border-radius: 16px;
        box-shadow: 0px 0px 20px 2px rgb(196 203 214 / 10%);

        @media (max-width: 1366px) {
            height: 100%;
            width: 100%;
        }
    }

    &.normal {
        .rumSelect__placeholder {
            font-size: 16px;
        }
    }
}

.rumSelect__placeholder {
    font-size: 16px;
    white-space: nowrap;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
}

.rumSelect__menu {
    box-shadow: 0 0 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    border-radius: 14px;
    overflow: hidden;
    z-index: 9999999 !important;
}

.rumSelect__control {
    height: 45px;

    svg {
        @media (max-width: 1024px) {
            width: 18px;
        }

    }
}