/*
**********************************************
* Dashboard Layout
**********************************************
*/
.dashboardLayout {

  .container,
  .container-fluid {
    max-width: unset;

    @media (min-width: 1920px) {
      max-width: 1920px;
    }
  }

  .dashboardLayoutContainer {
    padding: 20px 30px;

    @media (max-width: 992px) {
      padding: 20px 10px;
    }

    .dashboardSidebar {
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 24px;
      height: 100%;
      padding: 44px 0 60px 0;
      position: relative;

      @media (max-width: 1366px) {
        padding: 22px 0;
      }

      @media (max-width: 767px) {
        position: fixed;
        top: 0;
        left: auto;
        right: 100%;
        border-radius: 0;
        width: 100%;
        z-index: 999999;
        height: 100%;
        transition: 0.5s ease;
        overflow: scroll;

        &.active {
          right: 0;
        }
      }

      .crossMenu {
        position: absolute;
        top: 15px;
        right: 45px;
      }

      &.settingSideBar {
        padding: 30px 0;
        background-color: $theme-white;
        box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
        border-radius: 24px;
        border: 0;
        z-index: 0;

        @media (max-width: 767px) {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          margin-bottom: 20px;
        }
      }

      .logo-dashboard {
        display: flex;
        padding-bottom: 40px;
        padding-left: 30px;
        width: 164.1px;

        @media (max-width: 1366px) {
          padding-bottom: 30px;
          width: 125px;
        }
      }

      ul {
        text-decoration: none;
        list-style: none;
        padding-left: 0;

        li {
          text-decoration: none;
          position: relative;
          padding: 0 18px 0 14px;

          &:hover {
            .link-item {
              &.has-children {
                .nav-child-list {
                  opacity: 1;
                  visibility: visible;
                  display: block;
                }
              }
            }

          }

          &.hasChilList {
            position: relative;

            .plusIcon {
              display: block;
              position: absolute;
              top: 9px;
              right: 30px;
            }



          }

          .plusIcon {
            width: 13%;
            display: block;
            z-index: 200;
            position: relative;
            display: none;

            svg {
              width: 25px;
              height: 25px;
            }
          }

          &:hover,
          &:focus {
            .link-item {
              &.has-children {
                &::after {
                  border-left-color: $theme-blue;
                }
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: 2px;
          }

          .link-item {
            text-decoration: none;
            color: $text-grey;
            font-family: $baseFont;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            cursor: pointer;
            padding: 11px 0 11px 15px;
            border-radius: 10px;

            @media (max-width: 1366px) {
              font-size: 12px;
              padding: 9px;
            }

            @media (max-width: 992px) {
              padding: 5px;
              font-size: 10px;
            }

            @media (max-width: 767px) {
              font-size: 12px;
              padding: 9px;
            }



            .nav-bg {
              display: flex;
              align-items: center;
              border-radius: 10px;

              @media (max-width: 767px) {
                flex-wrap: wrap;

                span {
                  &:first-child {
                    width: 7%;
                  }

                  width: 80%;
                  text-align: left;
                }

                //** Plus icon for mobile */

              }


            }

            .settings-icon {
              padding-right: 10px;
            }

            &.active,
            &:hover,
            &:focus {
              color: $theme-blue;
              background-color: rgba($theme-blue, 0.10);
              font-weight: 700;
              transition: 0.2s ease-out;



              path {
                fill: #3b7afe;
              }

              ::after {
                content: " ";
                position: absolute;
                right: 0;
                bottom: 2px;
                margin: 0;
                padding: 0;
                width: 3.82px;
                height: 44px;
                border-radius: 2px;
                background-color: $theme-blue;
                border-radius: 50px;

                @media (max-width: 767px) {
                  content: '';
                }
              }
            }

            &.has-children {
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translate(0, -50%);
                border-radius: 10px;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid transparent;
                transition: 0.5s ease;

                @media (max-width: 767px) {
                  content: normal;
                }
              }

              .nav-child-list {
                background: $theme-white;
                border-radius: 10px;
                position: absolute;
                top: 0;
                right: 0;
                left: 100%;
                opacity: 0;
                visibility: visible;
                width: 220px;
                transition: 0.3s ease;
                z-index: 100;
                display: none;
                padding: 22px 20px;
                box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

                @media (max-width: 767px) {
                  opacity: 1;
                  visibility: visible;
                  left: auto;
                  top: auto;
                  position: relative;
                  display: block;
                  padding: 0;
                  border-radius: 0;
                  box-shadow: none;
                  background: transparent;
                  list-style: disc;
                  padding-left: 15%;
                  width: 100%;
                  height: 0;
                  overflow: scroll;

                  &.active {
                    height: 85px;
                  }
                }

                .nav-child-item {
                  padding: 0;

                  a {

                    font-family: $baseFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: $text-grey;
                    margin-bottom: 20px;
                    transition: all 0.5s ease;

                    @media (max-width: 767px) {
                      margin-bottom: 4px;
                      font-size: 12px;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                      color: $theme-blue;
                      font-weight: 700;
                      font-size: 14px;
                      -webkit-text-wrap: none;
                    }
                  }

                }


                .p_28 {
                  padding-top: 28px;
                }

                &:after {
                  content: normal !important;
                }

                .nav-child-item {
                  &:after {
                    content: normal;
                  }
                }

                a:after {
                  content: normal !important;
                }
              }



            }
          }

          .non-active {
            cursor: pointer;
            background: transparent !important;

            path {
              fill: #899ca8 !important;
            }

            ::after {
              display: none !important;
            }
          }
        }
      }



    }
  }
}

/*
**********************************************
* Fullpage Layout
**********************************************
*/

.fullpageLayout {
  .searchBarContainer {
    width: 100%;


  }

  .fullpageWrapper {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 24px;
    height: 88%;
    padding: 30px;
  }
}

/**********************************
* Search bar container - top bar
***********************************/
.topBarWrapper {
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }


}

.topBar-left {
  @media (max-width: 767px) {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }

  .list-inline-item {
    @media (max-width: 767px) {
      margin-right: 3px;
    }
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  // background: white;
  border-radius: 10px;
  box-shadow: 2px -3px 12px 3px rgb(196 203 214 / 10%);
  position: relative;
  z-index: 20;
  width: max-content;

  @media (max-width:1024px) {
    width: auto;
  }

  @media (max-width: 767px) {
    width: 90%;
  }

  .crossIcon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
  }

  .searchBar {
    .searchBar__menu {
      border: 0;
      border-radius: 14px;
      box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    }

    .menuSearchHead {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-heading {
        color: $theme-black;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.02em;

        @media (max-width: 992px) {
          font-size: 14px;
        }
      }

      .clearText {
        color: $text-grey;
        font-weight: 600;
        font-size: 14px;

        &:hover {
          cursor: pointer;
          color: $theme-blue;
        }

        @media (max-width: 992px) {
          font-size: 12px;
        }
      }
    }

    .search-wrapper {
      margin-bottom: 16px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        @media (max-width: 992px) {
          width: 15px;
          height: 15px;
        }
      }

      @media (max-width: 992px) {
        margin-bottom: 14px;
      }
    }

    .searchBar__menu-list {
      padding: 24px 39px;
      border: 0;
      border-radius: 14px;

      @media (max-width: 992px) {
        padding: 16px;
      }
    }

    .searchBar__control {
      height: 48px;
      width: 25em;
      background-color: $theme-white;
      border-color: $theme-white;
      border-radius: 14px;
      transition: all 0.5s ease;
      padding-left: 19px;

      @media (max-width: 1366px) {
        height: auto;
      }

      @media (max-width: 992px) {
        width: 18em;
      }

      @media (max-width: 767px) {
        width: 14em;
        padding-left: 5px;
      }

      @media (max-width: 575px) {
        width: 11em;
      }

      @media (max-width: 375px) {
        width: 10em;
      }

      &:focus {
        border-color: $theme-blue;
        width: 100%;
      }
    }

    .searchBar__option {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      display: flex;
      align-items: center;
      padding: 0;

      @media (max-width: 992px) {
        font-size: 12px;
      }

      &:active,
      &:focus {
        background-color: white !important;
        box-shadow: unset !important;
      }

      &:hover {
        color: $theme-blue;
      }

    }
  }

  .crossIcon {
    display: none;
  }

  .searchBar__input {

    &:focus,
    &:active {
      .searchBarContainer {
        .crossIcon {
          display: block;
        }
      }

    }
  }
}

.nosearchResult {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .noSearchImg {
    width: 250px;
    height: auto;

    @media (max-width: 1366px) {
      width: 170px;
    }

    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
}

/**************************************
* notification Icon - Top bar
****************************************/
.notificationContainer {
  background: $theme-white;
  border: 1px solid $theme-white;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  padding: 12px;
  border-radius: 14px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $theme-blue;

    svg {
      path {
        fill: $theme-blue;
      }
    }
  }

  @media (max-width: 1366px) {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    padding: 9px;
  }

  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
    padding: 6px;
  }
}


/**************************************
* profile box dropdown - Top bar
****************************************/
.topBar {
  .list-inline {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
}

.profileBtn {
  .dropdown-toggle {
    background: $theme-white;
    border: 1px solid $theme-white;
    //max-height: 40px;
    padding: 9px 18px 9px 11px;
    border-radius: 14px;

    @media (max-width: 1366px) {
      padding: 4px 11px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    &:after {
      content: unset;
    }

    &:hover,
    &:focus {
      background: $theme-white;
      border: 1px solid $theme-blue;

      svg {

        path {
          fill: $theme-blue;
        }
      }
    }
  }

  &.show {
    .profileBox {
      transition: all 0.5s ease;

      svg {
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }

  .dropdown-menu {
    padding: 15px 12px;
    border: 1px solid $theme-white;
    box-shadow: 0px 15px 20px 3px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-top: 8px;

    .dropdown-item {
      text-decoration: none;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      background: $theme-white;
      color: $text-grey;
      transition: font-weight 0.1s ease-in-out;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover,
      &:focus {
        color: $theme-blue;
        font-weight: 700;

        svg {
          path {
            fill: $theme-blue;
          }
        }
      }
    }
  }
}

.profileBox {
  cursor: pointer;

  .profileImg {
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center top;
    margin-right: 11px;
  }

  .profileName {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $theme-black;
    margin-right: 18px;

    @media (max-width: 1366px) {
      font-size: 14px;
      line-height: 1;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}

/**************************************
* filter bar - dashboard
****************************************/
.filterBar {
  margin-top: 35px;
  margin-bottom: 35px;

  .rumSelect {
    width: 166px;

    @media (max-width: 1366px) {
      width: 145px;
      height: 45px;
    }

    @media (max-width: 1024px) {
      width: auto;
    }

    @media (max-width: 992px) {
      width: 100px;
    }

    @media (max-width: 767px) {
      width: 90%;
    }

    .rumSelect__control {
      border: 0;

      .rumSelect__input {
        color: $text-grey;
      }

      .rumSelect__placeholder,
      .rumSelect__single-value {
        font-size: 16px;
        font-size: 700;
        color: $text-grey;

        @media (max-width: 1366px) {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }


  .searchIconFocus {
    background: $theme-white;
    border: 1px solid $theme-white;
    box-shadow: 0px 6px 10px 2px rgb(196 203 214 / 10%);
    padding: 12px;
    border-radius: 14px;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 1px solid $theme-blue;

      svg {
        path {
          fill: $theme-blue;
        }
      }
    }
  }

  .searchFilter {
    position: relative;
    overflow: hidden;

    .searchBarFilter {
      background: $theme-white;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 14px;
      padding: 12px 45px 12px 50px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20C13.4845 20 15.3102 19.3199 16.7567 18.18L17.1601 18.5742L20.2929 21.7071L20.3871 21.7903C20.7794 22.0953 21.3466 22.0676 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.5661 17.1518L18.1721 16.7668C19.3167 15.3187 20 13.4892 20 11.5C20 6.80558 16.1944 3 11.5 3ZM11.5 5C15.0899 5 18 7.91015 18 11.5C18 15.0899 15.0899 18 11.5 18C7.91015 18 5 15.0899 5 11.5C5 7.91015 7.91015 5 11.5 5Z' fill='%230A1629'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 13px center;
      height: 48px;
      max-width: 276px;
      color: $theme-black;
    }

    .btnCancel {
      background: none;
      border: none;
      padding: 2px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(-5px, -50%);

      &:hover,
      &:focus {
        svg {
          path {
            fill: $theme-blue;
          }
        }
      }
    }
  }
}


.pageContainer {
  background: #FFFFFF;
  box-shadow: 0px 20px 17px 8px rgb(196 203 214 / 10%);
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  padding: 0 29px;

  &.pridePage {
    .tab-content {
      height: 64vh;
    }
  }
}


/**************************************
* Humburger
****************************************/
.hamburger-menu {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
  }
}

#menu__toggle {
  opacity: 0;
}

// #menu__toggle:checked~.menu__btn>span {
//   transform: rotate(45deg);
// }

// #menu__toggle:checked~.menu__btn>span::before {
//   top: 0;
//   transform: rotate(0);
// }

// #menu__toggle:checked~.menu__btn>span::after {
//   top: 0;
//   transform: rotate(90deg);
// }

// #menu__toggle:checked~.menu__box {
//   visibility: visible;
//   left: 0;
// }




.menu__btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 26px;
  height: auto;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 767px) {
    width: 19px;
  }
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}

.menu__btn>span::before {
  content: '';
  top: -8px;
}

.menu__btn>span::after {
  content: '';
  top: 8px;
}