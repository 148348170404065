.glowBtn {
    min-width: 145px;
    height: 50px;
    background: $theme-blue;
    box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.263686);
    border-radius: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    transition: 0.5s ease;

    &:hover {
        background-color: #0b5ed7bf;
        border-color: #0a58ca42;
    }

    @media (max-width: 1366px) {
        padding: 10px 0;
        width: 115px;
        height: 45px;
        font-size: 14px;
    }

    @media (max-width: 1280px) {
        width: 140px;
    }

    @media (max-width: 1024px) {
        min-width: 100px;
        width: 100px;
    }

    @media (max-width: 992px) {
        height: 40px;
        font-size: 12px;
        width: 115px;
    }
}

.outlineBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    max-width: 166px;
    width: 166px;
    height: 50px;
    border: 1px solid $theme-blue;
    border-radius: 14px;
    background-color: transparent;
    color: $theme-blue;
    transition: 0.5s ease;

    &.rumSelect {
        .rumSelect__control {
            background-color: transparent !important;
        }
    }

    @media (max-width: 1366px) {
        padding: 10px 0;
        width: 115px;
        height: 45px;
        font-size: 14px;
    }

    @media (max-width: 1280px) {
        width: 140px;
    }

    @media (max-width: 992px) {
        height: 40px;
        font-size: 12px;
        width: 115px;
    }

    svg {
        margin-right: 10px;
    }

    &:hover {
        color: $theme-blue;
        background-color: transparent;
        filter: drop-shadow(0px 6px 12px rgba(63, 140, 255, 0.263686));
    }
}