/*
**********************************************
* Auth Layout
**********************************************
*/

.AuthLayout {
  position: relative;
  overflow: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;
  display: flex;
  align-items: center;

  @media (max-width: 1600px) {
    max-width: 990px;
  }

  @media (max-width: 1366px) {
    max-width: 1200px;
  }

  @media (max-width: 992px) {
    max-height: 100%;
  }

  //background: red;
  .authenticationLayout {
    background: $body-color;
    box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
    border-radius: 24px;
    width: 100%;
    height: 100vh;
    max-height: 780px;
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @media (max-width: 1366px) {
      max-height: 780px;
      height: 100%;
    }

    @media (max-width: 992px) {
      max-height: 680px;
    }

    .left {
      width: 50%;
      height: 100%;
      background: $theme-white;
      overflow: hidden;

      @media (max-width: 767px) {
        width: 100%;
      }

      .subAuthContainer {
        padding-top: 48px;
        padding-left: 95px;

        @media (max-width: 1600px) {
          padding-top: 80px;
        }

        @media (max-width: 1024px) {
          padding-left: 40px;
        }

        @media (max-width: 767px) {
          padding: 40px;
        }


        .m-t-50,
        .m-b-30 {
          @media (max-width: 1600px) {
            margin: 20px 0;
          }


          @media (max-width: 1280px) {
            margin: 50px 0;
          }

          @media (max-width: 1024px) {
            margin: 20px 0;
          }
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;
      background: $theme-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media (max-width: 767px) {
        display: none;
      }

      .img-cover {
        object-fit: cover;
        width: 100%;
        object-position: center;
        height: 100%;
      }
    }
  }

}