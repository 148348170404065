.myProfile {
    &__Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__Body {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    &__img {
        width: 68px;
        height: 68px;
        border-radius: 100%;
        border: 1px solid $theme-white;
        margin-right: 10px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__name {
        p {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: $theme-black;
        }
    }

    &__details {
        width: 260px;

        &__box {
            display: flex;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                width: 85px;
                color: $paragraph-grey;
                font-weight: 700;
            }

            p,
            a {
                font-weight: 400;
                font-size: 14px;
                color: #363636;
                text-decoration: none;
                margin: 0;
            }
        }
    }

    /** Edit Profile CSS */
    &.editProfile {
        .profile-img-wrapper {
            margin-bottom: 30px;
        }


    }
}


//** All setting box styling */

.settingInput {

    .themeInputGroup {
        .form-label {
            color: $text-grey;
        }

        .form-control {
            border: 1px solid #D8E0F0 !important;
            box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
            border-radius: 14px;

            &:focus {
                border-color: $theme-blue !important;
            }
        }
    }
}

.link {
    color: $theme-blue;
    text-decoration: underline;
}

.settingUpdatePassword {
    .themeInputGroup {
        &.passwordInput {
            .input-group {
                border-radius: 14px;
                overflow: hidden;
            }
        }
    }
}

.roundedForm {
    .themeInputGroup {
        .form-control {
            border-radius: 14px;
        }
    }
}