.dailyActivitiesWrapper {
    position: relative;

    .editBtn {
        position: absolute;
        top: 0;
        right: 0;
    }

    .dailyActivitiesContainer {
        height: 52em;
        padding-bottom: 3em;
        overflow: scroll;

        @media (max-width: 1366px) {
            height: 32em;
        }
    }

    .activiyBottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: white;
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
    }
}