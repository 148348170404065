.settingsWrapper {
    padding: 30px;
    background-color: $theme-white;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 24px;
}

.settingPageTitle {
    margin: 34px 0 18px 0;
}

/** Setting detail box Css */
.settingInnerHeight,
.settingSideBarHeight {
    height: calc(100vh - 180px);
}

.settingBoxWrapper {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;

    form {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;

        .m-t-50 {
            margin: 0;
        }
    }

    .settingBoxFooter {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}