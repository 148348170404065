/**************************************************
* Success Page Styling
**************************************************/
.successPage {
  position: relative;
  overflow-x: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;

  .successPageContainer {
    background: $body-color;
    box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
    border-radius: 24px;

    width: 100%;
    height: 100%;
    position: relative;

    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      /* identical to box height */

      text-align: center;
      margin-top: 100px;
    }
  }
}

/**************************************************
* 404 Page Styling
**************************************************/
.Page404 {
  position: relative;
  overflow-x: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;

  .Page404Container {
    background: $body-color;
    box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
    border-radius: 24px;

    width: 100%;
    height: 100vh;
    max-height: 780px;
    position: relative;

    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      /* identical to box height */

      text-align: center;
      margin-top: 80px;
    }
  }
}