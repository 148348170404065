.filterBarList {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 15;

    @media (max-width:992px) {
        margin-bottom: 10px;
    }

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }

    .list-inline-item {
        margin-right: 30px;

        @media (max-width: 1366px) {
            margin-right: 10px;
        }

        @media (max-width: 767px) {
            flex: 1 1 50%;
            margin-right: 0;
            margin-bottom: 11px;
        }

        &:first-child {
            @media (max-width: 992px) {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            @media (max-width: 767px) {
                justify-content: flex-start;
                flex: 1 1 100%;
            }

        }



        &:last-child {
            margin-right: 0;

            @media (max-width: 767px) {
                width: 100%;
            }

        }
    }
}

/**Expandable Search */
.exapandableSearch {
    @media (max-width: 767px) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    .input-group {
        background: white;
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding-right: 10px;
        box-shadow: 0px 0px 20px 2px rgb(196 203 214 / 10%);
        transition: 0.5s ease;
    }

    input {
        display: none;
        border: 0;
    }

    .crossIcon {
        display: none;
        cursor: pointer;
    }

    &.expanded {
        input {
            display: block;
        }

        .crossIcon {
            display: block;
        }

        .searchIcon {
            path {
                fill: $plane-black;
            }
        }
    }

    .searchIcon {
        padding: 10px;
        padding-right: 0;

        @media (max-width: 992px) {
            padding: 7px;
            padding-right: 0;

            svg {
                width: 16px;
            }
        }
    }
}