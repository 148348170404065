/*
**********************************************
* Default theme Colors
**********************************************
*/
/*
* Headings
*/
/*
* Paragraphs
*/
/*
* forms
*/
/*
* Errors
*/
/*
* Success
*/
.bg-blue {
  background-color: #126ADF;
}

.bg-white {
  background-color: #FFFFFF;
}

.greenColor {
  color: #5FBF24;
  line-height: 1;
}

.redColor {
  color: #E62E2E;
}

/*
**********************************************
* Default font name
**********************************************
*/
/*
**********************************************
* Default theme Global styling
**********************************************
*/
html,
body {
  height: 100%;
  position: relative;
}

body {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  color: #121212;
  background: #FCFCFC;
}

.container,
.container-fluid {
  max-width: 1366px;
}

p {
  font-family: "Nunito Sans", sans-serif;
  color: #7D8592;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 1600px) {
  p {
    line-height: 1;
    font-size: 14px;
  }
}
@media (max-width: 1366px) {
  p {
    font-size: 12px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito Sans", sans-serif;
  color: #121212;
}

@media (max-width: 1366px) {
  h3 {
    font-size: 21px;
  }
}

textarea {
  resize: none;
}

a {
  font-family: "Nunito Sans", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: #7D8592;
}

::-webkit-scrollbar-thumb {
  background: #126ADF;
}

::-webkit-scrollbar-thumb:hover {
  background: #126ADF;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:focus, .form-control:active {
  box-shadow: none;
  outline: none;
  border: 0;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.blue-color {
  color: #126ADF;
}

.underline-text {
  text-decoration: underline;
  transition: 0.9s ease;
}
.underline-text:hover {
  text-decoration: none;
}

.close-square svg path {
  transform-origin: 50% 50% 0;
  transition: all 0.5s ease;
}
.close-square:hover svg, .close-square:focus svg {
  position: relative;
  overflow: hidden;
}
.close-square:hover svg path, .close-square:focus svg path {
  transform: rotate(90deg);
}

.btn-close {
  transition: 0.5s ease;
  background-color: #F9FAFA;
  padding: 12px;
  border-radius: 14px;
  color: #121212;
}
.btn-close:hover, .btn-close:focus {
  box-shadow: 0px 0px 20px 2px rgb(196, 203, 214);
}

.Toastify {
  text-transform: capitalize !important;
}

/*
**********************************************
* utility classes
**********************************************
*/
.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-50 {
  margin-bottom: 50px;
}

/*
**********************************************
* heading and links
**********************************************
*/
.heading-small {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
}
@media (max-width: 1366px) {
  .heading-small {
    font-size: 18px;
  }
}

.heading-big {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  color: #121212;
}
@media (max-width: 1366px) {
  .heading-big {
    font-size: 24px;
  }
}

.link-inline {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #126ADF;
  text-decoration: none;
}
@media (max-width: 1600px) {
  .link-inline {
    font-size: 14px;
    line-height: 1;
  }
}
@media (max-width: 1366px) {
  .link-inline {
    font-size: 12px;
  }
}
.link-inline:hover, .link-inline:focus {
  text-decoration: underline;
  transition: all 0.8s ease;
}

/*
**********************************************
* css loader circle
**********************************************
*/
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.load {
  width: 24px;
  height: 24px;
  border: solid 4px rgba(2, 2, 2, 0.2);
  margin: 0px auto;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.load.white {
  border-right-color: #FFFFFF;
}
.load.blue {
  border-right-color: #126ADF;
}
.load.black {
  border-right-color: #121212;
}

.text-theme-blue {
  color: #126ADF;
}

/*
**********************************************
* Default theme form elements
**********************************************
*/
.themeInputGroup {
  /*
  * Password input field
  */
}
.themeInputGroup .rumSelect .rumSelect__control {
  border: 1px solid #D8E0F0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 14px;
}
.themeInputGroup .form-label {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
}
@media (max-width: 1366px) {
  .themeInputGroup .form-label {
    font-size: 13px;
    line-height: 1;
  }
}
.themeInputGroup .form-label svg {
  width: 18px;
}
.themeInputGroup .form-control {
  background: #FFFFFF;
  border: 1px solid #D8E0F0;
  border-radius: 7px;
  box-shadow: none;
  outline: none;
  padding: 13px 18px;
}
@media (max-width: 1366px) {
  .themeInputGroup .form-control {
    font-size: 12px;
  }
}
.themeInputGroup .form-control:focus {
  border-color: #126ADF;
  transition: all 1s ease-in;
}
.themeInputGroup .form-control.hasError {
  border-color: #FF5A4E;
  transition: all 1s ease-in;
}
.themeInputGroup .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #7D8592;
}
.themeInputGroup .form-control::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
  color: #7D8592;
}
.themeInputGroup .form-control::placeholder {
  color: #7D8592;
  font-size: 14px;
}
.themeInputGroup .form-text {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #FF5A4E;
}
@media (max-width: 1600px) {
  .themeInputGroup .form-text {
    font-size: 12px;
  }
}
@media (max-width: 1366px) {
  .themeInputGroup .form-text {
    font-size: 10px;
  }
}
.themeInputGroup.passwordInput .input-group {
  border: 1px solid #D8E0F0;
  border-radius: 7px;
}
.themeInputGroup.passwordInput .input-group.hasError {
  border-color: #FF5A4E !important;
  transition: all 1s ease-in;
}
.themeInputGroup.passwordInput .input-group:focus, .themeInputGroup.passwordInput .input-group:hover {
  border: 1px solid #126ADF;
  transition: all 1s ease-in;
}
.themeInputGroup.passwordInput .input-group .form-control {
  border: none;
  box-shadow: none;
  outline: none;
}
.themeInputGroup.passwordInput .input-group .btn-togglePassword {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
}
@media (max-width: 1366px) {
  .themeInputGroup.passwordInput .input-group .btn-togglePassword {
    width: 42px;
    padding: 0;
  }
}
.themeInputGroup.passwordInput .input-group .btn-togglePassword svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 1366px) {
  .themeInputGroup.passwordInput .input-group .btn-togglePassword svg {
    width: 18px;
  }
}

.btn-submit {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFF;
  padding-top: 13px;
  padding-bottom: 13px;
}
@media (max-width: 1366px) {
  .btn-submit {
    font-size: inherit;
  }
}
.btn-submit span {
  display: inline-block;
}
.btn-submit svg {
  opacity: 0;
  transform: translateX(-20px);
}
.btn-submit:hover svg, .btn-submit:focus svg {
  opacity: 1;
  transform: translateX(0);
  transition: all 1s ease;
}

/*
  * OTP input
  */
.OtpInput-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.OtpInput-container div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.OtpInput-container div .OtpInput input {
  width: 58px !important;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
  background: #FFFFFF;
  border: 1px solid #D8E0F0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2);
  border-radius: 14px;
}
.OtpInput-container div .OtpInput input::-moz-placeholder {
  color: #7D8592;
}
.OtpInput-container div .OtpInput input:-ms-input-placeholder {
  color: #7D8592;
}
.OtpInput-container div .OtpInput input::placeholder {
  color: #7D8592;
}
.OtpInput-container div .OtpInput input:focus {
  border: 1px solid #126ADF;
  outline: none;
  box-shadow: none;
}

/*
**********************************************
* Auth Layout
**********************************************
*/
.AuthLayout {
  position: relative;
  overflow: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;
  display: flex;
  align-items: center;
}
@media (max-width: 1600px) {
  .AuthLayout {
    max-width: 990px;
  }
}
@media (max-width: 1366px) {
  .AuthLayout {
    max-width: 1200px;
  }
}
@media (max-width: 992px) {
  .AuthLayout {
    max-height: 100%;
  }
}
.AuthLayout .authenticationLayout {
  background: #FCFCFC;
  box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
  border-radius: 24px;
  width: 100%;
  height: 100vh;
  max-height: 780px;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .AuthLayout .authenticationLayout {
    max-height: 780px;
    height: 100%;
  }
}
@media (max-width: 992px) {
  .AuthLayout .authenticationLayout {
    max-height: 680px;
  }
}
.AuthLayout .authenticationLayout .left {
  width: 50%;
  height: 100%;
  background: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 767px) {
  .AuthLayout .authenticationLayout .left {
    width: 100%;
  }
}
.AuthLayout .authenticationLayout .left .subAuthContainer {
  padding-top: 48px;
  padding-left: 95px;
}
@media (max-width: 1600px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer {
    padding-top: 80px;
  }
}
@media (max-width: 1024px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer {
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer {
    padding: 40px;
  }
}
@media (max-width: 1600px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer .m-t-50,
.AuthLayout .authenticationLayout .left .subAuthContainer .m-b-30 {
    margin: 20px 0;
  }
}
@media (max-width: 1280px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer .m-t-50,
.AuthLayout .authenticationLayout .left .subAuthContainer .m-b-30 {
    margin: 50px 0;
  }
}
@media (max-width: 1024px) {
  .AuthLayout .authenticationLayout .left .subAuthContainer .m-t-50,
.AuthLayout .authenticationLayout .left .subAuthContainer .m-b-30 {
    margin: 20px 0;
  }
}
.AuthLayout .authenticationLayout .right {
  width: 50%;
  height: 100%;
  background: #126ADF;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 767px) {
  .AuthLayout .authenticationLayout .right {
    display: none;
  }
}
.AuthLayout .authenticationLayout .right .img-cover {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  -o-object-position: center;
     object-position: center;
  height: 100%;
}

/*
**********************************************
* Registeration layout
**********************************************
*/
.RegisterLayout {
  position: relative;
  overflow: hidden;
  padding: 20px 40px 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;
}
@media (max-width: 1600px) {
  .RegisterLayout {
    max-width: 1280px;
  }
}
.RegisterLayout .RegisterLayoutContainer {
  background: #FCFCFC;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer {
  border-radius: 24px;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft {
  padding-left: 135px;
  padding: 176px 63px 176px 96px;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft {
    padding: 50px;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #FFFFFF;
  margin-bottom: 50px;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft h1 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft h1 {
    margin: 0;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps {
  position: relative;
  padding-left: 46px;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li {
  margin-bottom: 30px;
  opacity: 0.5;
  position: relative;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li:last-child {
  margin-bottom: 0;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li.active {
  opacity: 1;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li a {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 36px;
  text-decoration: none;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li a {
    font-size: inherit;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li::before {
  content: "";
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -44px;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  border: 2px solid #fff;
  border-radius: 100%;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingLeft .list-steps li:not(:last-child)::after {
  content: "";
  width: 2px;
  height: 26px;
  position: absolute;
  background: #FFFFFF;
  left: -33px;
  bottom: -28px;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight {
  position: relative;
  height: 100%;
  /*
  * radio groups specific to this sign up process only
  */
  /*
  * bottom bar that repeats on overall screens of registration process
  */
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer {
  padding: 0 255px;
  margin-top: 32px;
}
@media (max-width: 1200px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer {
    padding: 0 170px;
  }
}
@media (max-width: 1024px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer {
    padding: 30px;
    margin: 0;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer input {
  max-width: 370px;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .paddingContainer input {
    padding: 0.375rem 0.75rem;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .stepCounters {
  display: block;
  padding-top: 75px;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #126ADF;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .stepCounters {
    padding-top: 30px;
    margin-top: 2rem;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight h3 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
  text-align: center;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight h3 {
    font-size: inherit;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label {
  max-width: 170px;
  max-height: 200px;
  background: #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  transition: all 0.5s ease;
  text-align: center;
  padding: 40px 50px;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label {
    padding: 30px 50px;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label:hover {
  cursor: pointer;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label svg path {
  fill: #7D8592;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label span {
  display: block;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  margin-top: 32px;
}
@media (max-width: 1366px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup label span {
    font-size: inherit;
    margin-top: 14px;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup input[type=radio]:checked ~ label {
  border-bottom: 2px solid #126ADF;
  box-shadow: 0px 15px 20px rgba(184, 200, 224, 0.25);
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup input[type=radio]:checked ~ label svg path {
  fill: #126ADF;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .radioGroup:hover {
  cursor: pointer;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar {
  border-top: 1px solid #D8E0F0;
  padding: 17px 50px;
  background: #FFFFFF;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar {
    position: relative;
    padding: 12px;
  }
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar .list-inline {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar p {
    text-align: center;
  }
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar .btn-submit {
  text-align: center;
  padding: 14px 17.5px;
  width: 145px;
  border-radius: 14px;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar .btn-submit span {
  transform: translateX(15px);
  transition: all 0.5s ease;
}
.RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar .btn-submit:hover span, .RegisterLayout .RegisterLayoutContainer .stepsContainer .stepsSettingRight .bottomBar .btn-submit:focus span {
  transform: translateX(0);
}

/**************************************************
* Success Page Styling
**************************************************/
.successPage {
  position: relative;
  overflow-x: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
  height: 100vh;
}
.successPage .successPageContainer {
  background: #FCFCFC;
  box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  position: relative;
}
.successPage .successPageContainer h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  margin-top: 100px;
}

/**************************************************
* 404 Page Styling
**************************************************/
.Page404 {
  position: relative;
  overflow-x: hidden;
  padding: 20px 40px;
  max-width: 1440px;
  margin: 0px auto;
}
.Page404 .Page404Container {
  background: #FCFCFC;
  box-shadow: 0px 0px 20px 10px rgba(99, 99, 100, 0.1);
  border-radius: 24px;
  width: 100%;
  height: 100vh;
  max-height: 780px;
  position: relative;
}
.Page404 .Page404Container h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  margin-top: 80px;
}

/*
**********************************************
* Dashboard Layout
**********************************************
*/
.dashboardLayout .container,
.dashboardLayout .container-fluid {
  max-width: unset;
}
@media (min-width: 1920px) {
  .dashboardLayout .container,
.dashboardLayout .container-fluid {
    max-width: 1920px;
  }
}
.dashboardLayout .dashboardLayoutContainer {
  padding: 20px 30px;
}
@media (max-width: 992px) {
  .dashboardLayout .dashboardLayoutContainer {
    padding: 20px 10px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
  height: 100%;
  padding: 44px 0 60px 0;
  position: relative;
}
@media (max-width: 1366px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar {
    padding: 22px 0;
  }
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar {
    position: fixed;
    top: 0;
    left: auto;
    right: 100%;
    border-radius: 0;
    width: 100%;
    z-index: 999999;
    height: 100%;
    transition: 0.5s ease;
    overflow: scroll;
  }
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar.active {
    right: 0;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar .crossMenu {
  position: absolute;
  top: 15px;
  right: 45px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar.settingSideBar {
  padding: 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  border: 0;
  z-index: 0;
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar.settingSideBar {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 20px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar .logo-dashboard {
  display: flex;
  padding-bottom: 40px;
  padding-left: 30px;
  width: 164.1px;
}
@media (max-width: 1366px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar .logo-dashboard {
    padding-bottom: 30px;
    width: 125px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul {
  text-decoration: none;
  list-style: none;
  padding-left: 0;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li {
  text-decoration: none;
  position: relative;
  padding: 0 18px 0 14px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li:hover .link-item.has-children .nav-child-list {
  opacity: 1;
  visibility: visible;
  display: block;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li.hasChilList {
  position: relative;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li.hasChilList .plusIcon {
  display: block;
  position: absolute;
  top: 9px;
  right: 30px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .plusIcon {
  width: 13%;
  display: block;
  z-index: 200;
  position: relative;
  display: none;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .plusIcon svg {
  width: 25px;
  height: 25px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li:hover .link-item.has-children::after, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li:focus .link-item.has-children::after {
  border-left-color: #126ADF;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li:not(:last-child) {
  margin-bottom: 2px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item {
  text-decoration: none;
  color: #989898;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  padding: 11px 0 11px 15px;
  border-radius: 10px;
}
@media (max-width: 1366px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item {
    font-size: 12px;
    padding: 9px;
  }
}
@media (max-width: 992px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item {
    padding: 5px;
    font-size: 10px;
  }
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item {
    font-size: 12px;
    padding: 9px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item .nav-bg {
  display: flex;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item .nav-bg {
    flex-wrap: wrap;
  }
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item .nav-bg span {
    width: 80%;
    text-align: left;
  }
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item .nav-bg span:first-child {
    width: 7%;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item .settings-icon {
  padding-right: 10px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.active, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:hover, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:focus {
  color: #126ADF;
  background-color: rgba(18, 106, 223, 0.1);
  font-weight: 700;
  transition: 0.2s ease-out;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.active path, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:hover path, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:focus path {
  fill: #3b7afe;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.active ::after, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:hover ::after, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:focus ::after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 2px;
  margin: 0;
  padding: 0;
  width: 3.82px;
  height: 44px;
  border-radius: 2px;
  background-color: #126ADF;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.active ::after, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:hover ::after, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item:focus ::after {
    content: "";
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  border-radius: 10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  transition: 0.5s ease;
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children::after {
    content: normal;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list {
  background: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 100%;
  opacity: 0;
  visibility: visible;
  width: 220px;
  transition: 0.3s ease;
  z-index: 100;
  display: none;
  padding: 22px 20px;
  box-shadow: 0px 15px 20px 3px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list {
    opacity: 1;
    visibility: visible;
    left: auto;
    top: auto;
    position: relative;
    display: block;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    list-style: disc;
    padding-left: 15%;
    width: 100%;
    height: 0;
    overflow: scroll;
  }
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list.active {
    height: 85px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item {
  padding: 0;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #989898;
  margin-bottom: 20px;
  transition: all 0.5s ease;
}
@media (max-width: 767px) {
  .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a:hover, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a:focus, .dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item a.active {
  color: #126ADF;
  font-weight: 700;
  font-size: 14px;
  -webkit-text-wrap: none;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .p_28 {
  padding-top: 28px;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list:after {
  content: normal !important;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list .nav-child-item:after {
  content: normal;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .link-item.has-children .nav-child-list a:after {
  content: normal !important;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .non-active {
  cursor: pointer;
  background: transparent !important;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .non-active path {
  fill: #899ca8 !important;
}
.dashboardLayout .dashboardLayoutContainer .dashboardSidebar ul li .non-active ::after {
  display: none !important;
}

/*
**********************************************
* Fullpage Layout
**********************************************
*/
.fullpageLayout .searchBarContainer {
  width: 100%;
}
.fullpageLayout .fullpageWrapper {
  margin-top: 30px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  height: 88%;
  padding: 30px;
}

/**********************************
* Search bar container - top bar
***********************************/
@media (max-width: 767px) {
  .topBarWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .topBar-left {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}
@media (max-width: 767px) {
  .topBar-left .list-inline-item {
    margin-right: 3px;
  }
}

.searchBarContainer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px -3px 12px 3px rgba(196, 203, 214, 0.1);
  position: relative;
  z-index: 20;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
@media (max-width: 1024px) {
  .searchBarContainer {
    width: auto;
  }
}
@media (max-width: 767px) {
  .searchBarContainer {
    width: 90%;
  }
}
.searchBarContainer .crossIcon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}
.searchBarContainer .searchBar .searchBar__menu {
  border: 0;
  border-radius: 14px;
  box-shadow: 0px 15px 20px 3px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
}
.searchBarContainer .searchBar .menuSearchHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchBarContainer .searchBar .menuSearchHead .menu-heading {
  color: #121212;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.02em;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .menuSearchHead .menu-heading {
    font-size: 14px;
  }
}
.searchBarContainer .searchBar .menuSearchHead .clearText {
  color: #989898;
  font-weight: 600;
  font-size: 14px;
}
.searchBarContainer .searchBar .menuSearchHead .clearText:hover {
  cursor: pointer;
  color: #126ADF;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .menuSearchHead .clearText {
    font-size: 12px;
  }
}
.searchBarContainer .searchBar .search-wrapper {
  margin-bottom: 16px;
}
.searchBarContainer .searchBar .search-wrapper svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .search-wrapper svg {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .search-wrapper {
    margin-bottom: 14px;
  }
}
.searchBarContainer .searchBar .searchBar__menu-list {
  padding: 24px 39px;
  border: 0;
  border-radius: 14px;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__menu-list {
    padding: 16px;
  }
}
.searchBarContainer .searchBar .searchBar__control {
  height: 48px;
  width: 25em;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border-radius: 14px;
  transition: all 0.5s ease;
  padding-left: 19px;
}
@media (max-width: 1366px) {
  .searchBarContainer .searchBar .searchBar__control {
    height: auto;
  }
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 18em;
  }
}
@media (max-width: 767px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 14em;
    padding-left: 5px;
  }
}
@media (max-width: 575px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 11em;
  }
}
@media (max-width: 375px) {
  .searchBarContainer .searchBar .searchBar__control {
    width: 10em;
  }
}
.searchBarContainer .searchBar .searchBar__control:focus {
  border-color: #126ADF;
  width: 100%;
}
.searchBarContainer .searchBar .searchBar__option {
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0;
}
@media (max-width: 992px) {
  .searchBarContainer .searchBar .searchBar__option {
    font-size: 12px;
  }
}
.searchBarContainer .searchBar .searchBar__option:active, .searchBarContainer .searchBar .searchBar__option:focus {
  background-color: white !important;
  box-shadow: unset !important;
}
.searchBarContainer .searchBar .searchBar__option:hover {
  color: #126ADF;
}
.searchBarContainer .crossIcon {
  display: none;
}
.searchBarContainer .searchBar__input:focus .searchBarContainer .crossIcon, .searchBarContainer .searchBar__input:active .searchBarContainer .crossIcon {
  display: block;
}

.nosearchResult {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.nosearchResult .noSearchImg {
  width: 250px;
  height: auto;
}
@media (max-width: 1366px) {
  .nosearchResult .noSearchImg {
    width: 170px;
  }
}
.nosearchResult .noSearchImg svg,
.nosearchResult .noSearchImg img {
  height: 100%;
  width: 100%;
}

/**************************************
* notification Icon - Top bar
****************************************/
.notificationContainer {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  padding: 12px;
  border-radius: 14px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.notificationContainer:hover, .notificationContainer:focus {
  border: 1px solid #126ADF;
}
.notificationContainer:hover svg path, .notificationContainer:focus svg path {
  fill: #126ADF;
}
@media (max-width: 1366px) {
  .notificationContainer {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .notificationContainer {
    padding: 9px;
  }
}
@media (max-width: 767px) {
  .notificationContainer {
    width: 35px;
    height: 35px;
    padding: 6px;
  }
}

/**************************************
* profile box dropdown - Top bar
****************************************/
.topBar .list-inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.profileBtn .dropdown-toggle {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 9px 18px 9px 11px;
  border-radius: 14px;
}
@media (max-width: 1366px) {
  .profileBtn .dropdown-toggle {
    padding: 4px 11px;
  }
}
@media (max-width: 767px) {
  .profileBtn .dropdown-toggle {
    padding: 0;
  }
}
.profileBtn .dropdown-toggle:after {
  content: unset;
}
.profileBtn .dropdown-toggle:hover, .profileBtn .dropdown-toggle:focus {
  background: #FFFFFF;
  border: 1px solid #126ADF;
}
.profileBtn .dropdown-toggle:hover svg path, .profileBtn .dropdown-toggle:focus svg path {
  fill: #126ADF;
}
.profileBtn.show .profileBox {
  transition: all 0.5s ease;
}
.profileBtn.show .profileBox svg {
  transform: rotate(180deg);
  transform-origin: center;
}
.profileBtn .dropdown-menu {
  padding: 15px 12px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 15px 20px 3px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
  margin-top: 8px;
}
.profileBtn .dropdown-menu .dropdown-item {
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 0;
  background: #FFFFFF;
  color: #989898;
  transition: font-weight 0.1s ease-in-out;
}
.profileBtn .dropdown-menu .dropdown-item:not(:last-child) {
  margin-bottom: 20px;
}
.profileBtn .dropdown-menu .dropdown-item:hover, .profileBtn .dropdown-menu .dropdown-item:focus {
  color: #126ADF;
  font-weight: 700;
}
.profileBtn .dropdown-menu .dropdown-item:hover svg path, .profileBtn .dropdown-menu .dropdown-item:focus svg path {
  fill: #126ADF;
}

.profileBox {
  cursor: pointer;
}
.profileBox .profileImg {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  margin-right: 11px;
}
.profileBox .profileName {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  margin-right: 18px;
}
@media (max-width: 1366px) {
  .profileBox .profileName {
    font-size: 14px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .profileBox .profileName {
    display: none;
  }
}

/**************************************
* filter bar - dashboard
****************************************/
.filterBar {
  margin-top: 35px;
  margin-bottom: 35px;
}
.filterBar .rumSelect {
  width: 166px;
}
@media (max-width: 1366px) {
  .filterBar .rumSelect {
    width: 145px;
    height: 45px;
  }
}
@media (max-width: 1024px) {
  .filterBar .rumSelect {
    width: auto;
  }
}
@media (max-width: 992px) {
  .filterBar .rumSelect {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .filterBar .rumSelect {
    width: 90%;
  }
}
.filterBar .rumSelect .rumSelect__control {
  border: 0;
}
.filterBar .rumSelect .rumSelect__control .rumSelect__input {
  color: #989898;
}
.filterBar .rumSelect .rumSelect__control .rumSelect__placeholder,
.filterBar .rumSelect .rumSelect__control .rumSelect__single-value {
  font-size: 16px;
  font-size: 700;
  color: #989898;
}
@media (max-width: 1366px) {
  .filterBar .rumSelect .rumSelect__control .rumSelect__placeholder,
.filterBar .rumSelect .rumSelect__control .rumSelect__single-value {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .filterBar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.filterBar .searchIconFocus {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 6px 10px 2px rgba(196, 203, 214, 0.1);
  padding: 12px;
  border-radius: 14px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.filterBar .searchIconFocus:hover, .filterBar .searchIconFocus:focus {
  border: 1px solid #126ADF;
}
.filterBar .searchIconFocus:hover svg path, .filterBar .searchIconFocus:focus svg path {
  fill: #126ADF;
}
.filterBar .searchFilter {
  position: relative;
  overflow: hidden;
}
.filterBar .searchFilter .searchBarFilter {
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 45px 12px 50px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20C13.4845 20 15.3102 19.3199 16.7567 18.18L17.1601 18.5742L20.2929 21.7071L20.3871 21.7903C20.7794 22.0953 21.3466 22.0676 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.5661 17.1518L18.1721 16.7668C19.3167 15.3187 20 13.4892 20 11.5C20 6.80558 16.1944 3 11.5 3ZM11.5 5C15.0899 5 18 7.91015 18 11.5C18 15.0899 15.0899 18 11.5 18C7.91015 18 5 15.0899 5 11.5C5 7.91015 7.91015 5 11.5 5Z' fill='%230A1629'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 13px center;
  height: 48px;
  max-width: 276px;
  color: #121212;
}
.filterBar .searchFilter .btnCancel {
  background: none;
  border: none;
  padding: 2px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(-5px, -50%);
}
.filterBar .searchFilter .btnCancel:hover svg path, .filterBar .searchFilter .btnCancel:focus svg path {
  fill: #126ADF;
}

.pageContainer {
  background: #FFFFFF;
  box-shadow: 0px 20px 17px 8px rgba(196, 203, 214, 0.1);
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  padding: 0 29px;
}
.pageContainer.pridePage .tab-content {
  height: 64vh;
}

/**************************************
* Humburger
****************************************/
.hamburger-menu {
  display: none;
}
@media (max-width: 767px) {
  .hamburger-menu {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
  }
}

#menu__toggle {
  opacity: 0;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: relative;
  width: 26px;
  height: auto;
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 767px) {
  .menu__btn {
    width: 19px;
  }
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  content: "";
  top: -8px;
}

.menu__btn > span::after {
  content: "";
  top: 8px;
}

.notification-modal .modal-dialog {
  margin-right: 2rem;
}
@media (max-width: 767px) {
  .notification-modal .modal-dialog {
    margin-right: 0.5rem;
  }
}

.modal-header .btn-close {
  padding: 12px;
}

.uploadModal .modal-content,
.deleteModal .modal-content,
.editModal .modal-content {
  width: 460px;
  padding: 43px 30px 50px 38px;
  border-radius: 24px;
  border: 0;
}
.uploadModal .modal-content p,
.deleteModal .modal-content p,
.editModal .modal-content p {
  margin-top: 20px;
  margin-bottom: 41px;
  font-size: 20px;
  line-height: 29px;
  color: #989898;
}

.modal-body .rumSelect.normal .rumSelect__placeholder {
  font-size: 14px;
  color: #989898;
}

.employeeDetail p.columnHeading {
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}
.employeeDetail p.columnpara {
  font-size: 14px;
  display: inline-block;
  color: #121212;
  margin-bottom: 0;
  padding-right: 4px;
  line-height: 1;
}
.employeeDetail p.columnpara.lineHeight-1p1 {
  line-height: 1.2;
}

.uploadPopup .uploaded-file-container h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.uploadPopup .uploaded-file-container .uploading-wrapper .icon-wrapper {
  position: relative;
}
.uploadPopup .uploaded-file-container .uploading-wrapper .icon-wrapper svg {
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
}
.uploadPopup .uploaded-file-container .uploaded-wrapper .icon-wrapper {
  position: relative;
}
.uploadPopup .uploaded-file-container .uploaded-wrapper .icon-wrapper svg {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
}
.uploadPopup .glowBtn:disabled {
  background: #989898;
  border: none;
  box-shadow: none;
}

/** Notification Modal CSS **/
.notification-modal .modal-header {
  padding: 42px 30px 26px 40px;
}
@media (max-width: 767px) {
  .notification-modal .modal-header {
    padding: 20px;
  }
}
.notification-modal .modal-body {
  padding: 0;
}
.notification-modal .notication-card-container {
  height: calc(100vh - 220px);
  overflow: scroll;
}

/** Notification Card **/
.notificationCard {
  padding: 15px 80px 14px 30px;
  border-bottom: 1px solid #E4E6E8;
}
@media (max-width: 767px) {
  .notificationCard {
    padding: 10px;
  }
}
.notificationCard:last-child {
  border-bottom: 0;
}
.notificationCard .notification-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;
}
.notificationCard .notification-img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.notificationCard p {
  color: #0A1629;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.notificationCard small {
  font-weight: 600;
  font-size: 14px;
  color: #989898;
}

.noDetailFoundBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.noDetailFoundBox .noDetailFoundImage {
  width: 250px;
}
.noDetailFoundBox .noDetailFoundImage img {
  width: 100%;
  height: auto;
}

/**************************************
* Responsive
****************************************/
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 437px;
  }
}
.rs-table-cell-header.text-center .rs-table-cell-content {
  justify-content: center;
}
.rs-table-cell-header .rs-table-cell {
  border-bottom: 1px solid #f6f6f6;
}
.rs-table-cell-header .rs-table-cell-content,
.rs-table-cell-header .emplpoyeeNameCell {
  font-size: 16px;
  font-weight: 400;
  color: #989898;
  display: flex;
  align-items: center;
  overflow: unset;
}
@media (max-width: 1366px) {
  .rs-table-cell-header .rs-table-cell-content,
.rs-table-cell-header .emplpoyeeNameCell {
    font-size: 14px;
  }
}

.font-weight-bold.rs-table-cell-header .rs-table-cell-content {
  font-weight: bold;
  color: #121212;
}

.rs-table-cell-group-left-shadow,
.rs-table-cell-group-right-shadow,
.rs-table-cell-group-shadow {
  box-shadow: none;
}

.bgLightBlue {
  background-color: #f8fbfe;
}
.bgLightBlue .rs-table-cell {
  background-color: #f8fbfe;
}

.driveTimeTable .table-content-editing .rs-table-cell-content {
  padding: 2px 13px 3px 13px;
}
.driveTimeTable .table-content-editing .rs-table-cell-content .rs-input {
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}

.rs-input.error {
  border-color: red;
}
.rs-input.error:hover {
  border-color: red;
}

.rs-table-row {
  overflow: unset !important;
}

.rs-table-cell-content {
  font-size: 14px;
  color: #121212;
  font-weight: 400;
  overflow: unset;
}
@media (max-width: 1366px) {
  .rs-table-cell-content {
    font-size: 12px;
  }
}

.rs-table-cell-header-icon-sort {
  color: #989898;
}

.rs-table-row-header {
  border-bottom: 1px solid #F9FAFA;
}

.rs-table-cell {
  border: 0;
  overflow: unset;
}

.rs-table-scrollbar-vertical {
  width: 4px;
}
.rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
  width: 3px;
}

/** Editing Cell */
.table-content-editing .rs-table-cell-content {
  display: inline-flex;
  padding: 2px 13px 3px 0;
}

/** Pagination */
.rs-pagination-btn {
  background-color: #F9FAFA;
  color: #7D8592;
}
.rs-pagination-btn.rs-pagination-btn-active {
  background-color: #126ADF;
  color: white;
}
.rs-pagination-btn.rs-pagination-btn {
  padding: 7px 11px;
  line-height: 1;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}
.rs-pagination-btn.rs-pagination-btn-disabled {
  opacity: 0.5;
  background-color: #F9FAFA;
  color: #7D8592;
}

.pagiantion-box {
  margin-top: 43px;
  margin-bottom: 30px;
}

/** Dotted Action */
.editBtn {
  display: inline-flex;
}
.editBtn button,
.editBtn .EditIcon,
.editBtn .TrashIcon {
  background: #F9FAFA;
  color: #121212;
  border: 0;
  transition: 0.5s ease;
  font-size: 12px;
}
.editBtn button:hover,
.editBtn .EditIcon:hover,
.editBtn .TrashIcon:hover {
  background-color: #126ADF;
}
.editBtn button:hover svg path,
.editBtn .EditIcon:hover svg path,
.editBtn .TrashIcon:hover svg path {
  fill: white;
}

.emplpoyeeNameCell {
  display: flex;
  align-items: center;
}
.emplpoyeeNameCell .employeeMoreBtn {
  background: transparent;
  padding: 0;
  margin-left: 4px;
  line-height: 1;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  color: #126ADF;
  transition: 0.5s ease;
}
@media (max-width: 1366px) {
  .emplpoyeeNameCell .employeeMoreBtn {
    font-size: 12px;
  }
}
.emplpoyeeNameCell .employeeMoreBtn:hover {
  text-decoration: underline;
}
.emplpoyeeNameCell p {
  padding-right: 2px;
  margin: 0;
  color: #121212;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  overflow: unset;
}
@media (max-width: 1366px) {
  .emplpoyeeNameCell p {
    font-size: 12px;
  }
}

/**Report mix table css*/
.reportMixTable .rs-table-column-group-header {
  display: none;
}
.reportMixTable .rs-table-cell-content {
  justify-content: center;
}

.turnOverRow:last-child .emplpoyeeNameCell a {
  pointer-events: none;
  color: #121212;
}

.scrollable-table {
  overflow-x: scroll;
}

.tableCheckbox .form-check-input {
  width: 24px;
  height: 24px;
}
@media (max-width: 1366px) {
  .tableCheckbox .form-check-input {
    width: 20px;
    height: 20px;
  }
}

.readMoreBtn {
  background: transparent;
  padding: 0;
  margin-left: 4px;
  line-height: 1;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  color: #126ADF;
  transition: 0.5s ease;
}
@media (max-width: 1366px) {
  .readMoreBtn {
    font-size: 12px;
  }
}
.readMoreBtn:hover {
  text-decoration: underline;
}

.salesPercent span {
  font-size: 12px;
}

.countColumn .greenColor {
  color: #E62E2E;
}

.rs-table-body-info-wheel-area,
.rs-table-body-wheel-area {
  transition: unset !important;
}

.rumSelect__option--is-selected {
  color: #126ADF !important;
}

.css-1nmdiq5-menu {
  position: relative;
  z-index: 9999999;
}

.rumSelect {
  height: 50px;
  width: 166px;
}
.rumSelect.outlineBtn .rumSelect__control .rumSelect__placeholder {
  color: #126ADF;
}
.rumSelect.outlineBtn .rumSelect__control .rumSelect__indicator svg {
  fill: #126ADF;
}
@media (max-width: 1366px) {
  .rumSelect {
    width: 145px;
  }
}
@media (max-width: 992px) {
  .rumSelect {
    height: 42px;
  }
}
.rumSelect .css-1xc3v61-indicatorContainer {
  padding: 0;
}
.rumSelect .css-blhudp-control,
.rumSelect .css-v68sna-control {
  height: 100%;
  padding: 0 12px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 2px rgba(196, 203, 214, 0.1);
}
@media (max-width: 1366px) {
  .rumSelect .css-blhudp-control,
.rumSelect .css-v68sna-control {
    height: 100%;
    width: 100%;
  }
}
.rumSelect.normal .rumSelect__placeholder {
  font-size: 16px;
}

.rumSelect__placeholder {
  font-size: 16px;
  white-space: nowrap;
}
@media (max-width: 1366px) {
  .rumSelect__placeholder {
    font-size: 12px;
  }
}

.rumSelect__menu {
  box-shadow: 0 0 20px 3px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
  border-radius: 14px;
  overflow: hidden;
  z-index: 9999999 !important;
}

.rumSelect__control {
  height: 45px;
}
@media (max-width: 1024px) {
  .rumSelect__control svg {
    width: 18px;
  }
}

.filterBarList {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 15;
}
@media (max-width: 992px) {
  .filterBarList {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .filterBarList {
    flex-wrap: wrap;
  }
}
.filterBarList .list-inline-item {
  margin-right: 30px;
}
@media (max-width: 1366px) {
  .filterBarList .list-inline-item {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .filterBarList .list-inline-item {
    flex: 1 1 50%;
    margin-right: 0;
    margin-bottom: 11px;
  }
}
@media (max-width: 992px) {
  .filterBarList .list-inline-item:first-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .filterBarList .list-inline-item:first-child {
    justify-content: flex-start;
    flex: 1 1 100%;
  }
}
.filterBarList .list-inline-item:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .filterBarList .list-inline-item:last-child {
    width: 100%;
  }
}

/**Expandable Search */
@media (max-width: 767px) {
  .exapandableSearch {
    width: 100%;
  }
}
.exapandableSearch:hover {
  cursor: pointer;
}
.exapandableSearch .input-group {
  background: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-right: 10px;
  box-shadow: 0px 0px 20px 2px rgba(196, 203, 214, 0.1);
  transition: 0.5s ease;
}
.exapandableSearch input {
  display: none;
  border: 0;
}
.exapandableSearch .crossIcon {
  display: none;
  cursor: pointer;
}
.exapandableSearch.expanded input {
  display: block;
}
.exapandableSearch.expanded .crossIcon {
  display: block;
}
.exapandableSearch.expanded .searchIcon path {
  fill: #0A1629;
}
.exapandableSearch .searchIcon {
  padding: 10px;
  padding-right: 0;
}
@media (max-width: 992px) {
  .exapandableSearch .searchIcon {
    padding: 7px;
    padding-right: 0;
  }
  .exapandableSearch .searchIcon svg {
    width: 16px;
  }
}

.glowBtn {
  min-width: 145px;
  height: 50px;
  background: #126ADF;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.263686);
  border-radius: 14px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  transition: 0.5s ease;
}
.glowBtn:hover {
  background-color: rgba(11, 94, 215, 0.7490196078);
  border-color: rgba(10, 88, 202, 0.2588235294);
}
@media (max-width: 1366px) {
  .glowBtn {
    padding: 10px 0;
    width: 115px;
    height: 45px;
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .glowBtn {
    width: 140px;
  }
}
@media (max-width: 1024px) {
  .glowBtn {
    min-width: 100px;
    width: 100px;
  }
}
@media (max-width: 992px) {
  .glowBtn {
    height: 40px;
    font-size: 12px;
    width: 115px;
  }
}

.outlineBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  max-width: 166px;
  width: 166px;
  height: 50px;
  border: 1px solid #126ADF;
  border-radius: 14px;
  background-color: transparent;
  color: #126ADF;
  transition: 0.5s ease;
}
.outlineBtn.rumSelect .rumSelect__control {
  background-color: transparent !important;
}
@media (max-width: 1366px) {
  .outlineBtn {
    padding: 10px 0;
    width: 115px;
    height: 45px;
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .outlineBtn {
    width: 140px;
  }
}
@media (max-width: 992px) {
  .outlineBtn {
    height: 40px;
    font-size: 12px;
    width: 115px;
  }
}
.outlineBtn svg {
  margin-right: 10px;
}
.outlineBtn:hover {
  color: #126ADF;
  background-color: transparent;
  filter: drop-shadow(0px 6px 12px rgba(63, 140, 255, 0.263686));
}

.breadcrumb {
  margin: 0;
  margin-bottom: 7px;
}
.breadcrumb .breadcrumb-item {
  font-size: 16px;
}
@media (max-width: 1366px) {
  .breadcrumb .breadcrumb-item {
    font-size: 12px;
  }
}
.breadcrumb .breadcrumb-item a {
  font-weight: 400;
  font-size: 16px;
  color: #989898;
  transition: 0.5s ease;
  text-decoration: none;
}
@media (max-width: 1366px) {
  .breadcrumb .breadcrumb-item a {
    font-size: 12px;
  }
}
.breadcrumb .breadcrumb-item a:hover {
  color: #126ADF;
}

.searchFoundWrapper {
  height: 555px;
  overflow: scroll;
}
.searchFoundWrapper .SearchFoundList .searchFounditem {
  border-bottom: 1px solid #CFCFCF;
  padding: 15px 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .searhPageTitle {
  font-weight: 600;
  font-size: 16px;
  color: #121212;
  padding: 8px 0 12px 0;
  transition: 0.5s ease;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .searchPagePara {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #989898;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a {
  position: relative;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover {
  text-decoration: none;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover .arrowRightSvg {
  margin-right: 10px;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a:hover .searhPageTitle {
  color: #126ADF;
}
.searchFoundWrapper .SearchFoundList .searchFounditem a .arrowRightSvg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  transition: 0.5s ease;
  transition-property: margin;
}
.searchFoundWrapper .SearchFoundList .searchFounditem:first-child {
  padding-top: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem:last-child {
  border-bottom: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem p {
  margin: 0;
  padding: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb {
  margin: 0;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb .breadcrumb-item {
  color: #989898;
  font-size: 14px;
  padding-left: 3px;
}
.searchFoundWrapper .SearchFoundList .searchFounditem .breadcrumb .breadcrumb-item::before {
  padding-right: 3px;
}
.searchFoundWrapper .nosearchResult {
  height: 100%;
  display: flex;
  align-items: center;
}

.searchOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.2);
}

.settingsWrapper {
  padding: 30px;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
}

.settingPageTitle {
  margin: 34px 0 18px 0;
}

/** Setting detail box Css */
.settingInnerHeight,
.settingSideBarHeight {
  height: calc(100vh - 180px);
}

.settingBoxWrapper {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}
.settingBoxWrapper form {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}
.settingBoxWrapper form .m-t-50 {
  margin: 0;
}
.settingBoxWrapper .settingBoxFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.myProfile {
  /** Edit Profile CSS */
}
.myProfile__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myProfile__footer {
  display: flex;
  justify-content: flex-end;
}
.myProfile__Body {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}
.myProfile__img {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  margin-right: 10px;
  overflow: hidden;
}
.myProfile__img img {
  width: 100%;
}
.myProfile__name p {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #121212;
}
.myProfile__details {
  width: 260px;
}
.myProfile__details__box {
  display: flex;
  margin-bottom: 15px;
}
.myProfile__details__box:last-child {
  margin-bottom: 0;
}
.myProfile__details__box span {
  width: 85px;
  color: #7D8592;
  font-weight: 700;
}
.myProfile__details__box p,
.myProfile__details__box a {
  font-weight: 400;
  font-size: 14px;
  color: #363636;
  text-decoration: none;
  margin: 0;
}
.myProfile.editProfile .profile-img-wrapper {
  margin-bottom: 30px;
}

.settingInput .themeInputGroup .form-label {
  color: #989898;
}
.settingInput .themeInputGroup .form-control {
  border: 1px solid #D8E0F0 !important;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 14px;
}
.settingInput .themeInputGroup .form-control:focus {
  border-color: #126ADF !important;
}

.link {
  color: #126ADF;
  text-decoration: underline;
}

.settingUpdatePassword .themeInputGroup.passwordInput .input-group {
  border-radius: 14px;
  overflow: hidden;
}

.roundedForm .themeInputGroup .form-control {
  border-radius: 14px;
}

.innerFormScroll {
  height: 24rem;
  overflow: scroll;
  padding: 0 10px;
  margin: 0 -10px;
}

.rs-picker-menu {
  z-index: 9999;
}

.rs-picker-date .rs-picker-toggle {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}
.rs-picker-date .rs-picker-toggle-textbox {
  padding: 0;
}
.rs-picker-date .rs-picker-toggle-value {
  color: #121212 !important;
  font-size: 16px !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 0 !important;
}

.nav-tabs {
  margin-bottom: 28px;
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  padding: 29px 27px 29px 23px;
}
@media (max-width: 1366px) {
  .nav-tabs {
    margin-bottom: 18px;
    padding: 10px 27px 10px 23px;
  }
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  color: #989898;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  transition: 0.5s ease;
  text-align: center;
  text-transform: capitalize;
}
@media (max-width: 1366px) {
  .nav-tabs .nav-item .nav-link {
    font-size: 12px;
  }
}
.nav-tabs .nav-item .nav-link:focus-visible, .nav-tabs .nav-item .nav-link:focus-within {
  box-shadow: none;
  outline: none;
}
.nav-tabs .nav-item .nav-link.active {
  color: #126ADF;
  font-weight: 700;
}
.nav-tabs .nav-item .nav-link.active::after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 2px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #126ADF;
  border-radius: 50px;
  transition: 0.5s ease;
}
@media (max-width: 1366px) {
  .nav-tabs .nav-item .nav-link.active::after {
    height: 2px;
  }
}

.tabBoxContainer {
  background: #FFFFFF;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
  padding: 29px 27px 29px 23px;
}

.tab-content {
  position: relative;
  height: 83vh;
}
.tab-content .tab-pane {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.tab-content > .tab-pane {
  display: block;
  opacity: 1;
  opacity: 1;
  visibility: visible;
  width: 79vw;
}
.tab-content > .tab-pane.active {
  opacity: 1;
  visibility: visible;
  display: block;
  z-index: 20;
}

.tabs-Container .tabBoxContainer {
  box-shadow: none;
  overflow: scroll;
}

.pagination .page-item {
  margin: 0 5px;
}
.pagination .page-item.rotate180 {
  transform: rotateY(180deg);
}
.pagination .page-item.active .page-link {
  background: #126ADF;
  color: white;
}
.pagination .page-item:first-child {
  margin-left: 0;
}
.pagination .page-item:last-child {
  margin-right: 0;
}
.pagination .page-item span {
  font-weight: 800;
  font-size: 18px;
}
.pagination .page-item .page-link {
  border: 0;
  background: #F9FAFA;
  color: #7D8592;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1366px) {
  .pagination .page-item .page-link {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
}
.pagination .page-item .page-link:hover {
  text-decoration: none;
}
.pagination .page-item .page-link:focus, .pagination .page-item .page-link:active {
  box-shadow: none;
}

.dailyActivitiesWrapper {
  position: relative;
}
.dailyActivitiesWrapper .editBtn {
  position: absolute;
  top: 0;
  right: 0;
}
.dailyActivitiesWrapper .dailyActivitiesContainer {
  height: 52em;
  padding-bottom: 3em;
  overflow: scroll;
}
@media (max-width: 1366px) {
  .dailyActivitiesWrapper .dailyActivitiesContainer {
    height: 32em;
  }
}
.dailyActivitiesWrapper .activiyBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: white;
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}/*# sourceMappingURL=rum.css.map */