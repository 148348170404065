.pagination {
    .page-item {
        margin: 0 5px;

        &.doubleArrow {}

        &.rotate180 {
            transform: rotateY(180deg);
        }

        &.active {
            .page-link {
                background: $theme-blue;
                color: white;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        span {
            font-weight: 800;
            font-size: 18px;
        }

        .page-link {
            border: 0;
            background: $light-grey;
            color: $paragraph-grey;
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            text-decoration: none;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1366px) {
                width: 25px;
                height: 25px;
                font-size: 12px;
            }

            &:hover {
                text-decoration: none;
            }

            &:focus,
            &:active {
                box-shadow: none;
            }
        }
    }
}