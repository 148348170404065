.nav-tabs {
    margin-bottom: 28px;
    background: #FFFFFF;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 24px;
    padding: 29px 27px 29px 23px;

    @media (max-width: 1366px) {
        margin-bottom: 18px;
        padding: 10px 27px 10px 23px;
    }

    .nav-item {
        .nav-link {
            border: 0;
            color: $text-grey;
            font-family: $baseFont;
            font-style: normal;

            font-size: 14px;
            line-height: 19px;
            position: relative;
            transition: 0.5s ease;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1366px) {
                font-size: 12px;
            }

            &:focus-visible,
            &:focus-within {
                box-shadow: none;
                outline: none;
            }

            &.active {
                color: $theme-blue;
                font-weight: 700;

                &::after {
                    content: " ";
                    position: absolute;
                    right: 0;
                    bottom: 2px;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 3px;
                    border-radius: 2px;
                    background-color: $theme-blue;
                    border-radius: 50px;
                    transition: 0.5s ease;

                    @media (max-width: 1366px) {
                        height: 2px;
                    }
                }
            }

        }
    }
}

.tabBoxContainer {
    background: #FFFFFF;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 24px;
    padding: 29px 27px 29px 23px;
}


.tab-content {
    position: relative;
    height: 83vh;

    .tab-pane {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;


    }
}

.tab-content>.tab-pane {
    display: block;
    opacity: 1;
    opacity: 1;
    visibility: visible;
    width: 79vw;


    &.active {
        opacity: 1;
        visibility: visible;
        display: block;
        z-index: 20;
    }
}

.tabs-Container {
    .tabBoxContainer {
        box-shadow: none;
        overflow: scroll;
    }
}