.rs-picker-menu {
    z-index: 9999;
}

.rs-picker-date {
    .rs-picker-toggle {
        border: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .rs-picker-toggle-textbox {
        padding: 0;
    }

    .rs-picker-toggle-value {
        color: $theme-black !important;
        font-size: 16px !important;
    }
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    box-shadow: none;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 0 !important;
}