/*
**********************************************
* Default theme form elements
**********************************************
*/
.themeInputGroup {
  .rumSelect {
    .rumSelect__control {
      border: 1px solid #D8E0F0;
      box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
      border-radius: 14px;
    }
  }

  .form-label {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $label-grey;

    @media (max-width: 1366px) {
      font-size: 13px;
      line-height: 1;
    }

    svg {
      width: 18px;
    }
  }

  .form-control {
    background: $theme-white;
    border: 1px solid $border-grey;
    border-radius: 7px;
    box-shadow: none;
    outline: none;
    padding: 13px 18px;

    @media (max-width: 1366px) {
      font-size: 12px;
    }

    &:focus {
      border-color: $theme-blue;
      transition: all 1s ease-in;
    }

    &.hasError {
      border-color: $red-error;
      transition: all 1s ease-in;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari/Edge */
      color: $placeholder-grey;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $placeholder-grey;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $placeholder-grey;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 1;
      /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
      color: $placeholder-grey;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      opacity: 1;
      /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
      color: $placeholder-grey;
    }

    &::placeholder {
      color: $placeholder-grey;
      font-size: 14px;
    }
  }

  .form-text {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: $red-error;

    @media (max-width: 1600px) {
      font-size: 12px;
    }

    @media (max-width: 1366px) {
      font-size: 10px;
    }
  }


  /*
  * Password input field
  */
  &.passwordInput {
    .input-group {
      border: 1px solid $border-grey;
      border-radius: 7px;

      &.hasError {
        border-color: $red-error !important;
        transition: all 1s ease-in;
      }

      &:focus,
      &:hover {
        border: 1px solid $theme-blue;
        transition: all 1s ease-in;
      }

      .form-control {
        border: none;
        box-shadow: none;
        outline: none;
      }

      .btn-togglePassword {
        border: none;
        background: transparent;
        outline: none;
        box-shadow: none;

        @media (max-width: 1366px) {
          width: 42px;
          padding: 0;
        }

        svg {
          width: 100%;
          height: 100%;

          @media (max-width: 1366px) {
            width: 18px;
          }
        }
      }
    }

  }
}


.btn-submit {
  font-family: $baseFont;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: $theme-white;
  padding-top: 13px;
  padding-bottom: 13px;

  @media (max-width: 1366px) {
    font-size: inherit;
  }

  span {
    //transform: translateX(20px);
    display: inline-block;
  }

  svg {
    opacity: 0;
    //background: #f00;
    transform: translateX(-20px);
  }

  &:hover,
  &:focus {

    //span{
    //  transform: translateX(0);
    //  transition: all 1s ease;
    //}
    svg {
      opacity: 1;
      transform: translateX(0);
      transition: all 1s ease;
    }
  }
}

/*
  * OTP input
  */
.OtpInput-container {
  //border-top: 1px solid $theme-lpurple;
  //border-bottom: 1px solid $theme-lpurple;
  padding-top: 10px;
  padding-bottom: 10px;

  div {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .OtpInput {
      input {
        width: 58px !important;
        height: 50px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        margin-right: 16px;
        background: $theme-white;
        border: 1px solid $border-grey;
        box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2);
        border-radius: 14px;

        &::placeholder {
          color: $placeholder-grey;
        }

        &:focus {
          border: 1px solid $theme-blue;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}